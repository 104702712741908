import type { GetStaticProps } from 'next';

import { blogListData, caseStudiesData } from '@/prerender/prerender-urls';

export { default } from '@/routes/home';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      caseStudiesData,
      data: blogListData.slice(0, 3),
      seo: {
        cover: require('@/assets/images/preview.png').default.src,
        title: 'UUUSoftware - Canadian custom software development company',
        description:
          'We help clients build tailored digital solutions and port them to the cloud. We assemble dedicated teams of developers, DevOps engineers, and UX/UI specialists.'
      }
    }
  };
};

/* export const getStaticPaths = (async () => {
  return {
    paths: getStaticSitePaths().map(path => path.url),
    fallback: false, // false or "blocking"
  }
}) satisfies GetStaticPaths */

// getStaticProps
// getServerSideProps

/* export const getStaticProps = (async (props) => {
  console.log('props', props);
  // @ts-ignore
  const url = '/' + (props?.params?.pathitems?.join?.('/') || '');

  // const url = '/';
  console.log({url});

  const data = find(getStaticSitePaths(), {url});
  // const route = find(routes, {path: url});

  return {
    props: {
      data: blogListData.slice(0, 3),
      seo: {
        cover: require('@/assets/images/preview.png').default.src,
        title: 'UUUSoftware - Canadian custom software development company',
        description:
          'We help clients build tailored digital solutions and port them to the cloud. We assemble dedicated teams of developers, DevOps engineers, and UX/UI specialists.'
      }
    }
  }
}) satisfies GetStaticProps; */
