import Link from 'next/link';

import { ReactComponent as SVGArrow } from '@/assets/svg/arrow.svg';

import './index.scss';

export const ServiceItem = ({ title, icon, content, link }) => (
  <Link className="services-card" href={link}>
    {icon}
    <h3>{title}</h3>
    <p>{content}</p>
    <button>
      Learn more
      <SVGArrow />
    </button>
  </Link>
);
