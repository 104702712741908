import Link from 'next/link';

import { LazyImage } from '@/components/ui/lazy-image';

import './index.scss';

export const BlogInfo = ({ author, date, duration }) => (
  <div className="blog__info">
    <Link className="blog__avatar" href={`/profile/${author.slug}`} aria-label={author.name}>
      <LazyImage src={author.avatar} alt={author.name} width={60} height={60} />
    </Link>
    <div className="blog__content">
      <Link href={`/profile/${author.slug}`} className="blog__name">
        {author.name}
      </Link>
      <div>
        <span className="blog__date">{date} •</span>
        <span className="blog__duration">{duration} read</span>
      </div>
    </div>
  </div>
);
