import { useRef } from 'react';

import { useOnScreen } from '@/utils';

import './index.scss';

export const Animation = ({ animationType, children }) => {
  const animatedName = useRef(null);
  const isVisible = useOnScreen(animatedName);
  let animationClass = '';

  switch (animationType) {
    case 'cardTop':
      animationClass = 'animation-card-top';
      break;
    case 'rotationCounterClock':
      animationClass = 'animation-rotation-counterclock';
      break;
    default:
      animationClass = '';
  }

  return (
    <div
      className={`${animationClass} ${animationClass}--${isVisible ? 'active' : 'hidden'}`}
      //className={window.PRERENDER
      //>  ? undefined
      //  : `${animationClass} ${animationClass}--${isVisible ? 'active' : 'hidden'}`}
      ref={animatedName}
    >
      {children}
    </div>
  );
};
