import Link from 'next/link';
import { useRouter } from 'next/router';

import { BlogInfo } from '../blogInfo';
import { LazyImage } from '@/components/ui/lazy-image';

import './index.scss';

export const BlogItem = ({ post }) => {
  const router = useRouter();
  return (
    <li className="blog__item" onClick={() => router.push(`/blog/${post.slug}`)}>
      <LazyImage className="blog__img" src={post.previewImg} alt={post.title} />
      <BlogInfo author={post.authorData} date={post.convertedDate} duration={post.duration} />
      <p className="blog__title">
        <Link className="blog__link" href={`/blog/${post.slug}`}>
          {post.title}
        </Link>
      </p>
      <ul className="blog__tags">
        {post.category.map((item) => (
          <li className="blog__tags-item" key={item}>
            {item}
          </li>
        ))}
      </ul>
    </li>
  );
};
