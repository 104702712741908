import { ReactComponent as SVGCloud } from '@/assets/svg/cloud.svg';
import { ReactComponent as SVGMobDev } from '@/assets/svg/mobdev.svg';
import { ReactComponent as SVGOnshore } from '@/assets/svg/onshore.svg';
import { ReactComponent as SVGQa } from '@/assets/svg/qa.svg';
import { ReactComponent as SVGUxui } from '@/assets/svg/uxui.svg';
import { ReactComponent as SVGWebdev } from '@/assets/svg/webdev.svg';
import { ServiceItem } from '@/components/serviceItem';

const setvicesInfo = [
  {
    title: 'Web Development',
    content:
      'We help you build advanced web portals, including blockchain, big data and AI projects. Providing solutions to adjust to your business goals, we assist in creating web apps of any complexity. Whether we deal with web applications on-premise, cloud-based or serverless, our developers build secure and scalable backend solutions to deliver high-standard data processing.',
    link: '/services/web-development',
    icon: <SVGWebdev />
  },
  {
    title: 'Mobile Development',
    content:
      "Native or cross-platform apps — we build them all to increase your business' efficiency. Reach your audience with an outstanding mobile experience across all platforms and devices. We know how to impress your clients with a powerful combination of user-friendly design and the latest technologies. Every mobile app we develop transforms into a secure and competitive product.",
    link: '/services/mobile-app-development',
    icon: <SVGMobDev />
  },
  {
    title: 'Cloud Migration and DevOps',
    content:
      'Have your existing infrastructure on premises and are struggling to migrate it to the Cloud? Based on your goals, we help you move to the Cloud and maintain your infrastructure there. We provide our team of experienced DevOps engineers to identify a solution to any Cloud-related problem. We analyze your infrastructure, develop a plan, and come up with DevOps resources to implement it.',
    link: '/services/cloud-migrations-and-devops',
    icon: <SVGCloud />
  },
  {
    title: 'UX/UI design',
    content:
      "Not only do we create great-looking and easy-to-use UI interactions, but we also understand the engineering side. That's why we create interfaces every software developer will love and won't have to struggle to implement. If you’re thinking of a new prototype or would like to improve the usability of already an already existing product, we can help with all of these, including user-testing, AB-testing, and providing our best technical guidance.",
    link: '/services/ux-ui',
    icon: <SVGUxui />
  },
  {
    title: 'Onshore and Offshore Staffing',
    content:
      "Have a shortage of resources and can't fill positions for months? We assemble senior-level dedicated teams both onshore and offshore for startups and big enterprise clients. We can provide candidates in the USA and Canada, as well as offshore. All the candidates are not only shortlisted by our HR department, but also thoroughly interviewed by our architects. Only then, the profiles are handed to you.",
    link: '/services/onshore-and-offshore-staffing',
    icon: <SVGOnshore />
  },
  {
    title: 'QA Automation and Testing',
    content:
      'No matter whether you need to transform your existing QA department by introducing automation and increasing code coverage, or if you simply need additional QA resources — we have it all at your disposal. Being experienced in testing development and maintenance, we ensure your end-users have a seamless experience. We have strict time-frames to let your app get to market faster.',
    link: '/services/qa-and-testing',
    icon: <SVGQa />
  }
];

export const OurServices = () => (
  <section className="container section-padding ">
    <h2 className="section-title">Our services</h2>
    <div className="three-column-grid">
      {setvicesInfo.map((el, index) => (
        <ServiceItem {...el} key={index} />
      ))}
    </div>
  </section>
);
