import { OurServices } from './OurServices';
import { CaseStudiesSwiper } from '@/components/caseStudiesSwiper';
import { Clients } from '@/components/clients';
import { IndustriesList } from '@/components/industriesList';
import { Intro } from '@/components/intro';
import { OurBlog } from '@/components/ourBlog';
import { ReadyToLaunch } from '@/components/readyToLaunch';

import './index.scss';

const Home = (props) => {
  return (
    <>
      <Intro page="home" />
      <Clients />
      <OurServices />
      <IndustriesList />
      <ReadyToLaunch />
      <CaseStudiesSwiper caseStudiesData={props.caseStudiesData} />
      {props.data && <OurBlog blogList={props.data} />}
    </>
  );
};

export default Home;
